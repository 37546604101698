import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import commonStyles from "../lib/commonStyles"

const navLinks = [
  {
    url: "/",
    name: "Home",
  },
  {
    url: "/services",
    name: "Services",
  },
  {
    url: "/contact",
    name: "Contact",
  },
]

const Nav = styled.nav`
  position: ${props => (props.fixed ? "fixed" : "relative")};
  top: ${props => (props.fixed ? 0 : "auto")};
  left: ${props => (props.fixed ? 0 : "auto")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props =>
    props.variant === "generic" || props.fixed
      ? commonStyles.colors.darkGreen
      : "rgba(0, 0, 0, 0.4)"};
  padding: ${props => {
    if (props.variant === "home" && !props.fixed) return "50px"
    else if (props.variant === "generic" && !props.fixed) return "15px 75px"
    else if (props.fixed) return "10px 15px"
  }};
  width: ${props => (props.fixed ? "100%" : "auto")};

  @media (max-width: 1200px) {
    padding: ${props => {
      if (props.variant === "home" && !props.fixed) return "50px"
      else if (props.variant === "generic" && !props.fixed) return "15px 25px"
      else if (props.fixed) return "10px 15px"
    }};
  }

  @media (max-width: 400px) {
    padding: ${props => (props.fixed ? "10px 15px" : "15px")};
  }
  transition: ${props => (props.animated ? "transform 0.3s ease" : "none")};
  transform: ${props => (props.hidden ? "translateY(-68px)" : "translate(0)")};
  z-index: 100;
`

const Logo = styled(props => <Img {...props} />)`
  width: ${props => {
    if (props.variant === "generic" && !props.isFixed) return "250px"
    else if (props.variant === "home" && !props.isFixed) return "300px"
    else if (props.isFixed) return "150px"
  }};

  @media (max-width: 800px) {
    width: 30vw;
  }

  @media (max-width: 600px) {
    width: calc(30vw + 30px);
  }
`

const CloseButton = styled.button`
  background: transparent;
  color: white;
  border: none;
  position: absolute;
  top: 40px;
  right: 40px;

  @media (min-width: 1201px) {
    display: none;
  }
`

const HamburgerButton = styled.button`
background: transparent;
color: ${commonStyles.colors.lightGreen};
border: none;

  @media (min-width: 1201px) {
    display: none;
  }
`

const NavList = styled.ul`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${commonStyles.colors.darkGreen};
    transition: all 0.2s;
    z-index: ${props => (props.hidden ? -100 : 100)};
    transform: ${props => (props.hidden ? "scale(0)" : "scale(1)")};
    transform-origin: top right;
    visibility: ${props => (props.hidden ? "hidden" : "visible")};
    opacity: ${props => (props.hidden ? 0 : 1)};
  }
`
const NavItem = styled.li`
  &:not(:last-child) {
    padding-right: 50px;

    @media (max-width: 1200px) {
      padding-right: 0;
    }
  }

  @media (max-width: 1200px) {
    padding-bottom: 50px;
  }
  position: relative;
`

const HoverElem = styled.span`
  display: inline-block;
  width: 100%;
  height: 5px;
  background-color: ${commonStyles.colors.lightGreen};
  transform: ${props => {
    if (props.variant === "home" && !props.fixed) return "translateY(-80px)"
    else if (props.variant === "generic" && !props.fixed)
      return "translateY(-37px)"
    else if (props.fixed) return "translateY(-24px)"
  }};
  opacity: ${({ current, page }) => (current === page ? 1 : 0)};
  transition: opacity 0.3s;

  @media (max-width: 1200px) {
    display: none;
  }
`
const NavLink = styled(props => <Link {...props} />)`
  color: ${props =>
    props.variant === "home" ? "white" : commonStyles.colors.lightGreen};
  font-size: ${props => (props.variant === "home" ? "20px" : "22px")};
  &:hover ${HoverElem} {
    opacity: 1;
  }

  @media (max-width: 1200px) {
    font-size: 28px;
  }
`

const LinkTxt = styled.span`
  transform: ${props =>
    props.variant === "home" ? "translateY(-10px)" : "translateY(-15px)"};
  display: inline-block;
`

function Navbar({ variant, fixed, hidden, page, location, animated }) {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoWhite: file(relativePath: { eq: "logo-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [navHidden, setNavHidden] = useState(true)

  const generatedLinks = navLinks.map((link, index) => {
    return (
      <NavItem variant={variant} key={`navitem-${index}`}>
        <NavLink variant={variant} to={link.url}>
          <HoverElem
            page={page}
            fixed={fixed}
            variant={variant}
            location={location}
            current={link.name.toLowerCase()}
          />
          <LinkTxt variant={variant}>{link.name}</LinkTxt>
        </NavLink>
      </NavItem>
    )
  })

  generatedLinks.unshift(
    <CloseButton onClick={() => setNavHidden(true)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="currentColor"
        class="bi bi-x-lg"
        viewBox="0 0 16 16"
      >
        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
      </svg>
    </CloseButton>
  )

  return (
    <Nav fixed={fixed} animated={animated} hidden={hidden} variant={variant}>
      <Link to="/">
        <Logo
          isFixed={fixed}
          variant={variant}
          fluid={
            variant === "generic" || fixed
              ? data.logoWhite.childImageSharp.fluid
              : data.logo.childImageSharp.fluid
          }
        />
      </Link>
      <HamburgerButton
        onClick={() => setNavHidden(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="currentColor"
          class="bi bi-list"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
          />
        </svg>
      </HamburgerButton>
      <NavList hidden={navHidden}>{generatedLinks}</NavList>
    </Nav>
  )
}

Navbar.propTypes = {
  variant: PropTypes.string,
}
export default Navbar
